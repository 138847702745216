
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
} from "@ionic/vue";
import { INDUSTRIES } from "@/data/data.static";
import MaxahDetailedFooter from '@/components/DetailedFooter.vue';

export default {
  name: "Industries",
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
    MaxahDetailedFooter
  },
  mounted() {
    this.updateScrollNextTick();
  },
  data() {
    const allIndustries = INDUSTRIES;
    return {
      allIndustries,
    };
  },
  methods: {
    getId(industry) {
      return industry.name.replaceAll(/[^a-zA-Z ]/g, "").replaceAll(/\s/g,'').toLowerCase();
    },
    updateScrollNextTick() {
      this.$nextTick(function () {
        const hash = window.location.hash;
        if (hash) {
          const elmnt = document.querySelector(hash);
          setTimeout(() => {
            elmnt.scrollIntoView({
              behavior: "smooth",
            });
          }, 1000);
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to.hash) {
        const elmnt = document.querySelector(to.hash);
        setTimeout(() => {
          elmnt.scrollIntoView({
            behavior: "smooth",
          });
        }, 1000);
      }
    },
  },
};
