 <template>
  <ion-page>
    <ion-content class="maxah-content">
      <div
        v-for="industry in allIndustries"
        :key="industry.name"
        class="container"
        :id="getId(industry)"
      >
        <ion-card>
          <img :src="industry.coverImg" />
          <ion-card-header>
            <ion-card-subtitle>Industry</ion-card-subtitle>
            <ion-card-title>{{ industry.name }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            {{ industry.description }}
          </ion-card-content>
        </ion-card>
      </div>
      <MaxahDetailedFooter></MaxahDetailedFooter>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
} from "@ionic/vue";
import { INDUSTRIES } from "@/data/data.static";
import MaxahDetailedFooter from '@/components/DetailedFooter.vue';

export default {
  name: "Industries",
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
    MaxahDetailedFooter
  },
  mounted() {
    this.updateScrollNextTick();
  },
  data() {
    const allIndustries = INDUSTRIES;
    return {
      allIndustries,
    };
  },
  methods: {
    getId(industry) {
      return industry.name.replaceAll(/[^a-zA-Z ]/g, "").replaceAll(/\s/g,'').toLowerCase();
    },
    updateScrollNextTick() {
      this.$nextTick(function () {
        const hash = window.location.hash;
        if (hash) {
          const elmnt = document.querySelector(hash);
          setTimeout(() => {
            elmnt.scrollIntoView({
              behavior: "smooth",
            });
          }, 1000);
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to.hash) {
        const elmnt = document.querySelector(to.hash);
        setTimeout(() => {
          elmnt.scrollIntoView({
            behavior: "smooth",
          });
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 400px;
  padding: 1em;
}
img {
  width: 100%;
  max-height: 650px;
}
</style>